import { noticeDetail } from '@/utils/home.js';
export default {
    name: "NoticeContent",
    data() {
        return {
            htmlContent: ""
        }
    },
    created() {
        this.noticeDetailFunc();
    },
    methods: {
        // 获取详情
        noticeDetailFunc() {
            let data = {
                id: this.$route.query.aId
            }
            noticeDetail(data).then(res => {
                if (res && res.code == "0") {
                  this.htmlContent = res.data.concent
                }
            })
        }
    }
}